import React from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Label,
  FormGroup,
} from "reactstrap";
import PageTitle from "../../components/Common/PageTitle";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { Link } from "react-router-dom";
import Loader from "../../shared/loader/loader";
import { useCouponHook } from "./couponHook";

const AddEditCoupon = (props) => {
  const {
    updateCoupon,
    loader,
    pageTitle,
    coupon,
    setCoupon,
    toggleSwitch,
    settoggleSwitch,
  } = useCouponHook(props);

  const validateDateRange = (value, context) => {
    const validFromDate = new Date(context.fromDate);
    const validTillDate = new Date(value);

    if (validFromDate > validTillDate) {
      return "Valid From date cannot exceed Valid Till date.";
    }
    return true;
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <PageTitle title={pageTitle} />

          <AvForm
            className="needs-validation"
            onValidSubmit={(e, v) => {
              updateCoupon(e, v);
            }}
          >
            <Row>
              <Col lg="12">
                <Card>
                  <CardBody>
                    <h4 className="card-title">Coupon Information</h4>
                    <p className="card-title-desc">
                      Fill all information below
                    </p>
                    <Row className="agora-lato">
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="description">Description</Label>
                          <AvField
                            name="description"
                            placeholder="Enter description"
                            type="text"
                            errorMessage="Please enter description"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="description"
                            value={coupon.description}
                            maxLength={50}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="validFrom">Valid From</Label>
                          <AvField
                            name="validFrom"
                            placeholder="Enter valid from date"
                            type="date"
                            errorMessage="Please enter valid from date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="validFrom"
                            value={coupon.validFrom}
                            min={coupon.validFrom}
                            onChange={(e) => {
                              setCoupon((prevState) => {
                                return {
                                  ...prevState,
                                  expireDate: e.target.value,
                                };
                              });
                            }}
                          />
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="expireDate">Valid till</Label>
                          <AvField
                            name="expireDate"
                            placeholder="Enter valid till date"
                            type="date"
                            errorMessage="Please enter valid till date"
                            className="form-control"
                            validate={{ required: { value: true } }}
                            id="expireDate"
                            value={coupon.expireDate}
                            min={coupon.expireDate}
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="discount">Discount %</Label>
                          <AvField
                            name="discount"
                            placeholder="Enter discount amount"
                            type="numeric"
                            errorMessage="Please provide a discount."
                            className="form-control"
                            validate={{
                              required: {
                                value: true,
                                errorMessage: "Please provide a discount.",
                              },
                              pattern: {
                                value: /^\d+(\.\d{1,3})?$/,
                                errorMessage:
                                  "Please provide a valid positive number with up to 3 decimal places.",
                                custom: [
                                  {
                                    validator: validateDateRange,
                                    context: { fromDate: coupon.expireDate },
                                  },
                                ],
                              },
                              min: {
                                value: 0,
                                errorMessage:
                                  "Please provide a valid positive number.",
                              },
                            }}
                            value={coupon.discount || ""}
                            max="100"
                          />
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <FormGroup>
                          <Label htmlFor="code">Code Name</Label>
                          <AvField
                            name="code"
                            placeholder="Enter code name"
                            type="text"
                            className="form-control"
                            id="code"
                            value={coupon.code}
                            maxLength={50}
                          />
                         
                        </FormGroup>
                      </Col>
                      <Col md="6">
                        <Label htmlFor="status">Status</Label>
                        <div
                          className="custom-control status-switch custom-switch mb-2"
                          dir="ltr"
                        >
                          <input
                            type="checkbox"
                            className="custom-control-input"
                            id="status"
                            checked={toggleSwitch}
                            onChange={(e) => {
                              setCoupon((prevState) => {
                                return {
                                  ...prevState,
                                  isActive: !prevState.isActive,
                                };
                              });
                            }}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor="status"
                            onClick={(e) => {
                              settoggleSwitch(!toggleSwitch);
                            }}
                          >
                            {toggleSwitch ? "Active" : "Inactive"}
                          </label>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>

            <Row>
              <Col lg="12" className="agora-form-buttons text-right">
                <Link
                  to="/coupons"
                  className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light"
                >
                  Cancel
                </Link>
                <Button
                  type="submit"
                  className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                >
                  Save
                </Button>
              </Col>
            </Row>
          </AvForm>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddEditCoupon;
