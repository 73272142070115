import React, { useState, useEffect } from "react";

import {
  ShowNotification,
  GetQueryParam,
} from "../../helpers/general/generalHelpers";

import { saveCoupon, getCoupon } from "./services/coupon-service";

export function useCouponHook(props) {
  const [toggleSwitch, settoggleSwitch] = useState(true);
  const [loader, setLoader] = useState(false);
  const [pageTitle, setPageTitle] = useState("Add Coupon");
  const [coupon, setCoupon] = useState({
    description: "",
    validFrom: new Date().toLocaleDateString("en-CA").slice(0, 10),
    expireDate: new Date().toLocaleDateString("en-CA").slice(0, 10),
    isDefault: false,
    discount: 0,
    isActive: true,
    code: ''
  });

  useEffect(() => {
    if (props.location.search) {
      setLoader(true);
      setPageTitle("Edit Coupon");
      const couponId = GetQueryParam(props.location.search, "id");
      console.log(couponId);
      getCoupon(couponId)
        .then((response) => {
          console.log(response.data.coupon);
          setCoupon(() => {
            return {
              expireDate: new Date(response.data.coupon.CodeDetail.ExpireDate)
                .toLocaleDateString("en-CA")
                .slice(0, 10),
              validFrom: new Date(response.data.coupon.CodeDetail.ValidFrom)
                .toLocaleDateString("en-CA")
                .slice(0, 10),
              description: response.data.coupon.CodeDetail.Description,
              isDefault: response.data.coupon.CodeDetail.IsDefault,
              discount: response.data.coupon.CodeDetail.Discount,
              isActive: response.data.coupon.IsActive,
              code: response.data.coupon.Code
            };
          });
          settoggleSwitch(response.data.coupon.IsActive);
        })
        .catch((error) => {
          const message =
            error && error.response && error.response.data
              ? error.response.data.message
              : "There was an error fetching user information, please try again!";
          ShowNotification({ type: "error", message });
          throw error;
        })
        .finally(() => {
          setLoader(false);
        });
    }
  }, [props.location.search]);

  function updateCoupon(event, values) {
    setLoader(true);
    values.isDefault = false;
    values.type = "COUPON";
    values.isActive = toggleSwitch;
    saveCoupon(values, props.history, setLoader);
  }

  return {
    updateCoupon,
    loader,
    pageTitle,
    coupon,
    setCoupon,
    toggleSwitch,
    settoggleSwitch,
  };
}

