import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Pagination,
  PaginationItem,
  PaginationLink,
  FormGroup,
  Label,
  Button
} from 'reactstrap';

import { SetStatusStyle, ShowNotification, ObjectHasValues } from '../../helpers/general/generalHelpers';
import { user, userRoles } from '../../helpers/user/userInfo';
import Loader from '../../shared/loader/loader';
import { getPaginationInformation, initialPaginationObject } from '../../helpers/general/pagination';
import { getTasks } from './Services/task-service';
import { getSubjects } from '../Subjects/Services/subject-service';
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import ExportTasksCSV from '../../components/Tasks/tasks-export-csv';
import SweetAlert from 'react-bootstrap-sweetalert';
import { deleteTask } from './Services/task-service';

const TasksList = (props) => {
  const [pagination, setPagination] = useState(initialPaginationObject);
  const [currentPage, setCurrentPage] = useState(1);
  const filterForm = useRef(null);
  const [filters, setFilters] = useState(null);
  const [loader, setLoader] = useState(false);
  const [tasks, setTasks] = useState([]);
  const [taskIdToDelete, setTaskIdToDelete] = useState('');
  const [subjects, setSubjects] = useState([]);
  const [confirmation, setConfirmation] = useState(false);

  if (props.location.notification && props.location.notification.show) {
    const notification = props.location.notification;
    ShowNotification({
      type: 'success',
      message: notification.message
    });
    props.location.notification.show = false;
  }

  useEffect(() => {
    setPagination(getPaginationInformation(tasks.length, currentPage));
    setLoader(true);

    getTasks(currentPage, filters)
      .then((response) => {
        const newTasks = response.data.task;
        setTasks(newTasks);

        setPagination(getPaginationInformation(response.data.Count, currentPage));
        getSubjects().then((response) => setSubjects(response.data.subjects));
      })

      .catch((error) => {
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage, filters]);

  function paginate(pageNumber) {
    setCurrentPage(pageNumber);
  }

  function handleFilters(event, values) {
    if (ObjectHasValues(values)) {
      setFilters(values);
    }
  }

  function clearFilters() {
    filterForm.current.reset();
    setFilters(null);
  }

  function deleteTaskEntry(taskId) {
    setLoader(true);

    deleteTask(taskId)
      .then((response) => {
        ShowNotification({ type: 'success', message: 'Task deleted successfully!' });
        const updatedTasks = tasks.filter((task) => task._id !== taskId);
        setTasks(updatedTasks);
      })
      .catch((error) => {
        ShowNotification({ type: 'error', message: "Couldn't delete the task. Please try again!" });
        throw error;
      })
      .finally(() => {
        setLoader(false);
      });
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="relative">
          <Loader loading={loader} />
          {/* Render Page title */}
          <Row>
            <Col xs="12">
              <div className="page-title-box d-flex align-items-center justify-content-between">
                <h4 className="mb-0 font-size-18"> {props.title} </h4>
                <div className="page-title-right">
                  <ExportTasksCSV filters={filters} />
                </div>
              </div>
            </Col>
          </Row>
          <Row>
            <Col lg="12">
              <Card>
                <CardBody>
                  <AvForm
                    ref={filterForm}
                    onValidSubmit={(e, v) => {
                      handleFilters(e, v);
                    }}
                  >
                    <Row>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="title"> Assessment Title: </Label>
                          <AvField
                            name="assessment"
                            placeholder="Search"
                            type="text"
                            className="form-control filter-field"
                            id="assessment-title-filter"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter"> Subject </Label>
                          <AvField
                            type="select"
                            name="subject"
                            id="status-filter"
                            className="form-control filter-field"
                          >
                            <option value="">Please select the subject</option>
                            {subjects.map((subject, i) => (
                              <option key={i} value={subject._id}>
                                {`${subject.title} - ${subject.studyLevelId}`}
                              </option>
                            ))}
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="role"> Learner ID </Label>
                          <AvField
                            name="learner"
                            type="text"
                            placeholder="Search"
                            className="form-control filter-field"
                          />
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto">
                        <FormGroup>
                          <Label htmlFor="status-filter"> Status </Label>
                          <AvField type="select" name="status" id="status-filter" className="form-control filter-field">
                            <option value=""> Select One </option>
                            <option value="Pending"> Pending </option>
                            <option value="Accepted"> Accepted </option>
                            <option value="Done"> Done </option>
                            <option value="Declined"> Declined </option>
                          </AvField>
                        </FormGroup>
                      </Col>
                      <Col className="col-sm-12 col-md-auto agora-filter-buttons">
                        <Button
                          type="submit"
                          className="btn btn-primary agora-button save-button border-radius waves-effect waves-light"
                        >
                          Filter
                        </Button>
                        <Button
                          type="button"
                          onClick={clearFilters}
                          className="btn btn-primary agora-button cancel-button border-radius waves-effect waves-light ml-2"
                        >
                          Clear
                        </Button>
                      </Col>
                    </Row>
                  </AvForm>
                  <div className="table-responsive">
                    <Table className="table-centered table-nowrap table-hover">
                      <thead className="thead-light font-size-15">
                        <tr>
                          <th scope="col"> Assessment Title </th>
                          <th scope="col"> Subject </th>
                          <th scope="col"> Learner Name </th>
                          <th scope="col"> Learner ID </th>
                          {user.user?.role === userRoles.admin ? <th scope="col"> Assignee name </th> : null}
                          <th scope="col"> Last Updated </th>
                          <th scope="col"> Status </th>
                          <th scope="col" className="text-center">
                            Action
                          </th>
                        </tr>
                      </thead>
                      <tbody className="agora-lato">
                        {tasks.map((task, i) => (
                          <tr key={'_task_' + i}>
                            <td> {task?.assessment?.title} </td>
                            <td> {`${task?.subject?.title} - ${task?.subject?.studyLevelId}`} </td>
                            <td>
                              {task?.learner === undefined || task?.learner === null ? (
                                <span style={{ color: 'red' }}>Unidentified</span>
                              ) : (
                                `${task.learner?.firstName} ${task.learner?.lastName}`
                              )}
                            </td>
                            <td>
                              {task?.learner === undefined || task?.learner === null ? (
                                <span style={{ color: 'red' }}>Unidentified</span>
                              ) : (
                                `${task.learner?._id}`
                              )}
                            </td>
                            {/*<td> {task?.learner?._id} </td>*/}
                            {user.user?.role === userRoles.admin ? (
                              <td>
                                {task?.assignee === undefined || task?.assignee === null ? (
                                  <span style={{ color: 'red' }}>Not assigned</span>
                                ) : (
                                  `${task.assignee?.firstName} ${task.assignee?.lastName}`
                                )}
                              </td>
                            ) : null}
                            <td>
                              {new Intl.DateTimeFormat('en-GB', {
                                year: 'numeric',
                                month: 'short',
                                day: '2-digit',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                              }).format(new Date(task.updatedAt))}
                            </td>
                            <td>
                              <div>
                                <SetStatusStyle status={task.status} />
                              </div>
                            </td>
                            <td className="text-center">
                              <ul className="list-inline font-size-20 contact-links mb-0">
                                <li className="list-inline-item px-2">
                                  {task?.learner ? (
                                    <Link
                                      to={{
                                        pathname: '/task',
                                        search: '?id=' + task._id,
                                        state: {
                                          currentTask: task
                                        }
                                      }}
                                      id={'profile' + task._id}
                                    >
                                      <i className="bx bx-show-alt"> </i>
                                      <UncontrolledTooltip placement="top" target={'profile' + task._id}>
                                        View
                                      </UncontrolledTooltip>
                                    </Link>
                                  ) : user.user?.role === userRoles.admin ? (
                                    <Button
                                      onClick={(e) => {
                                        setConfirmation(true);
                                        setTaskIdToDelete(task._id);
                                      }}
                                      className="agora-action-button font-size-20"
                                      id={'delete-task' + task._id}
                                    >
                                      <i className="bx bxs-trash-alt"></i>
                                      <UncontrolledTooltip placement="top" target={'delete-task' + task._id}>
                                        Delete
                                      </UncontrolledTooltip>
                                    </Button>
                                  ) : null}

                                  {confirmation ? (
                                    <SweetAlert
                                      title="Are you sure?"
                                      danger
                                      showCancel
                                      confirmBtnText="Delete Task"
                                      confirmBtnBsStyle="danger"
                                      cancelBtnText="Cancel"
                                      cancelBtnBsStyle="basic"
                                      onConfirm={() => {
                                        setConfirmation(false);
                                        deleteTaskEntry(taskIdToDelete);
                                      }}
                                      onCancel={() => {
                                        setConfirmation(false);
                                      }}
                                    >
                                      You won't be able to revert this!
                                    </SweetAlert>
                                  ) : null}
                                </li>
                              </ul>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>

                  <Row>
                    <Col lg="12">
                      <Pagination className="pagination pagination-rounded justify-content-center mt-4">
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            first
                            onClick={(e) => {
                              paginate(1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === 1}>
                          <PaginationLink
                            previous
                            onClick={(e) => {
                              paginate(pagination.currentPage - 1);
                            }}
                          />
                        </PaginationItem>
                        {pagination.pages.map((page, index) => (
                          <PaginationItem key={'_page_' + index} active={page === pagination.currentPage}>
                            <PaginationLink
                              onClick={(e) => {
                                paginate(page);
                              }}
                            >
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        ))}
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            next
                            onClick={(e) => {
                              paginate(pagination.currentPage + 1);
                            }}
                          />
                        </PaginationItem>
                        <PaginationItem disabled={pagination.currentPage === pagination.totalPages}>
                          <PaginationLink
                            last
                            onClick={(e) => {
                              paginate(pagination.totalPages);
                            }}
                          />
                        </PaginationItem>
                      </Pagination>
                      <div className="records-count text-center">
                        <p>
                          Showing <strong> {pagination.startIndex + 1} </strong> to{' '}
                          <strong> {pagination.endIndex + 1} </strong> of <strong> {pagination.totalItems} </strong>{' '}
                          records
                        </p>
                      </div>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TasksList;
